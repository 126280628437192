<template>
    <component :is="getStep" v-if="isDataSet"></component>
</template>

<script>
import {mapGetters} from 'vuex';
import ChooseCity from '@/components/ChooseCity';
import ChooseAction from "@/components/ChooseAction";
import CertificateCatalog from "@/components/CertificateCatalog";
import ShoppingCart from "@/components/ShoppingCart";
import ThanksBlock from "@/components/ThanksBlock";
import ActivateCertificate from "@/components/ActivateCertificate";
import SuccessActivate from "@/components/SuccessActivate";
import SupportBlock from "@/components/SupportBlock";

/**
 * Component LayoutDefault
 * @property {Store} $store
 * @property {QVueGlobals} $q
 */
export default {
    name: 'LayoutDefault',
    components: {
        ChooseCity,
        ChooseAction,
        CertificateCatalog,
        ShoppingCart,
        ThanksBlock,
        ActivateCertificate,
        SuccessActivate,
        SupportBlock
    },
    data() {
        return {
            isDataSet: false
        }
    },
    methods: {
        /**
         * Destroy app
         * @param {Boolean} clearStore
         */
        destroy(clearStore=false) {
            if (clearStore) this.$store.dispatch('clearStore');
            this.$.appContext.app.unmount();
        },
        async initStore() {
            // await this.$store.dispatch('initStore');
            const result = await this.$store.dispatch('set');
            if (result) {
                this.isDataSet = true;
            }
            await this.initStep();
        },
        async initStep() {
            const urlParams = new URLSearchParams(window.location.search);

            await this.$store.dispatch('data/setUtm', this.getUtmFromRequest(urlParams));

            if (urlParams.has("roistat_visit")) {
              const roistat = urlParams.get('roistat_visit');
              await this.$store.dispatch('data/setRoistat', roistat);
            }

            const city = urlParams.get('city');
            const cert = urlParams.get('cert');

            const serviceId = urlParams.get('facility');
            const timeId = parseInt(urlParams.get('time'));

            let step = null;
            const successUrl = urlParams.get('success');

            if (urlParams.get('payment') === '1') {
                if (successUrl !== null) {
                    parent.postMessage(
                        JSON.stringify({
                            action: "paydoc-widget-redirect",
                            url: successUrl
                        }),
                        "*"
                    );
                } else {
                    step = 'ThanksBlock';
                }
            }
            else if (urlParams.get('payment') === '0') step = 'SupportBlock';
            else if (urlParams.get('activate') !== null) step = 'ActivateCertificate';
            else if (city !== null) {
                await this.$store.dispatch('data/setRegion', city);
                await this.$store.dispatch('facilities/set', city);
                await this.$store.dispatch('parlour/set', city);
                await this.$store.dispatch('promo/set', city);
                step = 'CertificateCatalog';
                if (cert !== null && String(cert).match(/\D/) === null) {
                    await this.$store.dispatch('facilities/setCustomCertificate', cert);
                    await this.$store.dispatch('data/addMoneyCertificate', cert);
                    await this.$store.dispatch('catalog/setSection', 'sum');
                }
                if (serviceId && timeId) {
                    await this.$store.dispatch('data/addService', { serviceId, timeId });
                }
            }
            else step = this.step ? this.step : 'ChooseCity';
            await this.$store.dispatch('step/setStep', step);
        },

        /**
         * @param {URLSearchParams} urlParams
         * @return Object
         */
        getUtmFromRequest(urlParams) {
            return {
                utm_source: urlParams.get("utm_source") ?? null,
                utm_medium: urlParams.get("utm_medium") ?? null,
                utm_campaign: urlParams.get("utm_campaign") ?? null,
                utm_content: urlParams.get("utm_content") ?? null,
                utm_term: urlParams.get("utm_term") ?? null,
                widget_source: urlParams.get("widget_source") ?? null,
                widget_content: urlParams.get("widget_content") ?? null,
            };
        }
    },
    computed: {
        ...mapGetters({
            step: 'step/step',
            test: 'facilities/facilities'
        }),
        getStep() {
            return this.step;
        },
        layout() {
            return window.innerWidth < 500 ? 'mobile' : 'pc';
        },
        isMobile() { return this.layout === 'mobile'; }
    },
    created() {
        // let self = this;
        // window.addEventListener('beforeunload', function (e) {
        //     self.$store.dispatch('saveStore');
        //     e.preventDefault();
        // });
        this.initStore();
    },
    beforeUnmount() {
        // this.$store.dispatch('saveStore');
    }
}
</script>

<style>
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url('./styles/fonts/Roboto/Roboto-Regular.ttf') format("truetype");
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url('./styles/fonts/Roboto/Roboto-Bold.ttf');
    font-weight: bold;
}
html, body, #app {
    /*height: 100%;*/
    /*background-color: #9C949C;*/
    font-family: 'Roboto';
    /*display: inline-block;*/
    scrollbar-width: thin;
}
::-webkit-scrollbar {
    width: 5px;
    height: 15px;
}

::-webkit-scrollbar-track-piece {
    background-color: #F1F3F6;
}

::-webkit-scrollbar-thumb:vertical {
    -webkit-border-radius: 8px;
    background-color: #A0A9B6;
}
</style>
<style lang="scss">
.main {
    margin: auto;
    width: $main-width;
    border-radius: $main-border-radius;
    background-color: white;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
}

p.title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #03081A;
}

@media screen and (max-width: $main-width) {
    .main {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
}
</style>
